@import "@styles/_variables.scss";
.ab-text-photo {
  padding-top: 139px;
  padding-left: Max(102px, calc((100% - 1440px) / 2 + 102px));
  padding-bottom: 0;
  padding-right: Max(112px, calc((100% - 1440px) / 2 + 112px));

  box-sizing: border-box;
  height: 734px;
  background-color: #372f2f;
  position: relative;
  z-index: 1;

  @media screen and (max-width: $tablet-breakpoint) {
    padding: 98px 29px;
    min-height: 734px;
  }
  & > span {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    & > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.8;
      z-index: -1;
      object-fit: cover;

      &#mobile {
        display: none !important;

        @media screen and (max-width: 850px) {
          display: block !important;
        }
      }

      &#desktop {
        @media screen and (max-width: 850px) {
          display: none !important;
        }
      }
    }
  }

  h2 {
    color: #fff;
    width: 100%;
    max-width: 568px;
  }

  & > p {
    margin-top: 59px;
    color: #fff;
    width: 100%;
    max-width: 481px;

    & > a {
      color: #a79669;
      cursor: pointer;
    }
  }

  & > .ab-link {
    margin-top: 25px;
    color: #fff !important;
    border-bottom-color: #fff;
  }
}
